import React from "react";
import character from "../media/Character Design Icon.png";
import Illustration from "../media/Illustration Icon.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
function Section2() {
  return (
    <div
      className="what-i-do d-flex align-items-center justify-content-center position-relative "
      style={{ minHeight: "75vh" }}
    >
      <div className="container">
        <div className="row m-0 justify-content-center">
          <motion.div
            className="col-lg-5 col-12 text-center fontitalic display-3 position-relative cus-heasd"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0 }}
            viewport={{ once: true }}
          >
            What I do best
            {/* <svg
              className="curved-underline w-75 m-0"
              viewBox="0 0 200 20"
              xmlns="http://www.w3.org/2000/svg"
              style={{ top: "50%", transform: "translateY(-90%)" }}
            >
              <path
                d="M 10 15 Q 100 10 190 15"
                style={{
                  stroke: "#ff97dc",
                  strokeWidth: 3,
                  fill: "transparent",
                }}
              />
            </svg> */}
          </motion.div>

          <div className="col-12 ">
            <div className="row m-0 justify-content-evenly">
              <div className="col-lg-5 col-12 text-center px-lg-5 px-2 cus-cols">
                <motion.div
                  className="d-flex justify-content-center align-items-center mx-auto d-md-block d-none"
                  style={{ width: "200px", height: "170px" }}
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  <img
                    src={Illustration}
                    className="img-fluid mx-auto w-75 some-cus"
                    alt="Character Design"
                  />
                </motion.div>
                <motion.div
                  className="d-flex justify-content-center align-items-center mx-auto d-lg-none d-block "
                  style={{ width: "100px", height: "70px" }}
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  <img
                    src={Illustration}
                    className="img-fluid mx-auto w-75 py-2 some-cus"
                    alt="Character Design"
                  />
                </motion.div>
                <motion.div
                  className="text-main text-uppercase fontbold fs-4 py-lg-2 py-3 cus-cols-heads"
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  Illustration
                </motion.div>
                <motion.p
                  className=" fs-5  fontitalic"
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  From storyboarding to vector graphics, editorial illustrations
                  to iconography, I can help with all your visual development
                  needs!
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  <Link
                    className="btn mt-2 bg-main py-lg-2 fs-6 text-light rounded-1 px-lg-5 px-2 py-1"
                    to={"/illustration"}
                    >
                    View Portfolio
                  </Link>
                </motion.p>
              </div>
              <div className="col-lg-5 col-12 text-center px-lg-5 px-2 mt-lg-0 mt-5 cus-cols">
                <motion.div
                  className="d-lg-flex d-none justify-content-center align-items-center mx-auto"
                  style={{ width: "200px", height: "170px" }}
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  <img
                    src={character}
                    className="img-fluid mx-auto w-75"
                    alt="Illustration"
                  />
                </motion.div>{" "}
                <motion.div
                  className="d-flex justify-content-center align-items-center mx-auto d-lg-none d-block"
                  style={{ width: "100px", height: "70px" }}
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  <img
                    src={character}
                    className="img-fluid mx-auto w-100"
                    alt="Character Design"
                  />
                </motion.div>
                <motion.div
                  className="text-main text-uppercase fontbold fs-4 py-lg-2 py-3 cus-cols-heads"
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  CHARACTER DESIGN
                </motion.div>
                <motion.p
                  className=" fs-5  fontitalic"
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  In my downtime, you’ll always find me doodling little animals
                  goofing about. Sometimes pretty ladies too. But hey - that’s
                  not all I draw!
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  <Link
                    className="btn mt-2 bg-main py-lg-2 fs-6 text-light rounded-1 px-lg-5 px-2 py-1"
                    to="/chrachterdesigns"
                    >
                    View Portfolio
                  </Link>
                </motion.p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
