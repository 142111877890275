import { motion } from "framer-motion";
import React from "react";
function Section1() {
  return (
    <div
      className="col-12 d-flex align-items-center bg-main position-relative sec-one-mob"
      style={{
        minHeight: "65vh",
      }}
    >
      <div className="container py-lg-0 py-5 cus-mob-pad">
        <div className="row m-0 justify-content-between cus-font-mob">
          <motion.div
            className="col-12 align-self-center"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0 }}
            viewport={{ once: true }}
          >
            <p className="cus-mar text-light display-3 pt-0 fontitalic d-md-block d-none ">
              Hello, I’m Saloni, an illustrator, animator and self professed
              lover of color! I’m based in Mumbai where I spend most of my time
              drawing silly cats and playful patterns.
            </p>{" "}
            <p className="cus-mar text-light fs-3 pt-0 fontitalic d-md-none d-block">
              Hello, I’m Saloni, an illustrator, animator and self professed
              lover of color! I’m based in Mumbai where I spend most of my time
              drawing silly cats and playful patterns.
            </p>
          </motion.div>
  
        </div>
      </div>
    </div>
  );
}

export default Section1;
